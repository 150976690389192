import { ActionsObservable, Epic, StateObservable, ofType } from 'redux-observable';
import actions from './actions';
import phenomenonActions from '../phenomenon/actions';
import { catchError, concatMap, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { Action, initialPageableQuery, UUID } from '../app/basicModels';
import methodologyActions from '../methodology/actions';
import { concat, forkJoin, merge, of } from 'rxjs';
import { notification } from '../../components';
import {
  getMethodology,
  getMethodologyOnBase,
  saveMethodology,
  saveMethodologyOnBase,
  cloneMethodology,
  getPhenomenaByMethodology,
  getExtraDimensionsByMethodology
} from '../methodology/services';
import {
  addCategoryPhenomenaToMethodology,
  insertComponentsOnMethodologyDeep,
  addOrUpdateInMethodologyDeepByCharacteristic,
  checkIfCharacteristicIsUsedAndRemoveFromMethodology,
  convertFromMethodologyDeep,
  convertToMethodologyDeep,
  createCategoryWithPhenomena,
  deleteInspectionGroup,
  getCanonicalIdsToClone,
  getUpdatedMethodology,
  removeMultPhenonIndicatorMethodologyDeep,
  removePhenomenonFromMethodology,
  reorderMethodologyDeep,
  toggleDefaultMethodology,
  toggleShowFlag,
  upsertInspectionGroup,
  checkIntegrityPhenomenaTree,
  createComponentDeepFromPhenomenonTree,
  getMethodologyDeepComponentDeep,
  getCharacteristicIdsUsedInMethodology,
  addExtraDimensionToMethodology,
  removeExtraDimensionFromMethodology,
  getCustomVariables
} from './services';
import { getCompany, getSeasonsTreeByCompany } from '../company/service';
import { getCrops } from '../crop/service';
import {
  Characteristic,
  AreaVariableType,
  CustomVariableType,
  Company,
  Methodology,
  Phenomenon,
  templateCompanyId,
  User,
  Indicator
} from '../models';
import { buildCommandRequest } from '../methodology/utils';
import { message } from 'antd';
import {
  ComponentDeep,
  MethodologyDeep,
  MethodologyUpdatePayload,
  PhenomenonDeep,
  CategoryDeep,
  InspectionGroupDeep,
  CharacteristicDeep,
  CustomVariablesGetDTO,
  SaveMethodologyPayload
} from './models';
import { getMessageError, hasInspectionLayoutMoreThanNCharacteristics, isObjectReferenceCanonical, updateBasicInformation } from './utils';
import characteristicActions from '../characteristic/actions';
import methodologyTemplateActions from '../methodologyTemplate/actions';
import variableValidationActions from '../variableValidation/actions';
import { clonePhenomenon, getPhenomenon } from '../phenomenon/service';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { cloneCharacteristic, getCharacteristic, getIndependentVariables } from '../characteristic/service';
import { cloneIndicator, getIndicator } from '../indicator/service';
import { findObjectById } from '../../helpers';
import { parsePhenomenonSingleToPhenomenon } from '../phenomenon/utils';
import { upsertComponentsIntoPhenomenon } from '../../containers/FixedPointCategories/utils';
import { Dictionary } from 'redux-ngrx-entity';
import { CommandRequest } from '../../helpers/request/models';
import { CompanyActions, CropActions } from '../actions';
import { ExtraDimension } from '../extraDimensions/models';
import { ErrorMsg, notifyErrorOrFallback, notifyMethodologyErrorOrFallback } from '../../settings/models/Error';
import { SimpleCharacteristicsIndicatorsProps } from '../../components/characteristicsIndicators/models/characteristicsIndicators.model';
import { cloneDeep } from 'lodash';
import { AppState } from '../redux.model';
import { getValueLanguage } from '../language/utils';
import { trackingEvent } from '../../helpers/useTrackingEvents';

export const handleLoadMethodologyDeep = (action$, state$) => {
  let methodology;
  return action$.pipe(
    ofType(actions.LOAD_METHODOLOGY_DEEP),
    map((action: Action<{ id: UUID }>) => (action.payload as { id: UUID }).id),
    concatMap((methodologyId: UUID) =>
      forkJoin([
        getMethodology(methodologyId),
        getMethodologyOnBase(methodologyId).pipe(
          catchError(() =>
            of({
              data: {
                seasonIds: [],
                seasonPropertyIds: [],
                seasonAreaIds: []
              }
            })
          )
        )
      ])
    ),
    map((results: any) => {
      const [demeter, base] = results;
      methodology = {
        ...demeter.data,
        season_ids: base.data.seasonIds,
        season_property_ids: base.data.seasonPropertyIds,
        season_area_ids: base.data.seasonAreaIds,
        default_methodology: base.data.defaultMethodology
      };
      return methodology;
    }),
    concatMap((results: any) => {
      const companyEntities = state$.value.Company.entities as Dictionary<Company>;
      const enableLocationCrop = state$.value.App.systemFlags.enableLocationCrop as boolean;
      return forkJoin([
        of(results),
        getPhenomenaByMethodology(methodology.id),
        companyEntities[methodology.company_id]
          ? of({ data: companyEntities[methodology.company_id] })
          : getCompany(methodology.company_id),
        getCrops({ ...initialPageableQuery }, methodology.company_id, true, 'ALL', enableLocationCrop),
        getSeasonsTreeByCompany(methodology.company_id),
        getIndependentVariables({ ...initialPageableQuery }, methodology.company_id),
        getExtraDimensionsByMethodology(methodology.id)
      ]);
    }),
    concatMap(
      ([methodologyWithSeason, phenomenaByMethodology, company, crops, seasonsTree, independentVariables, extraDimensions]: any) => {
        const phenomenonsTree = phenomenaByMethodology.data.map(phenomena => parsePhenomenonSingleToPhenomenon(phenomena));
        checkIntegrityPhenomenaTree(phenomenonsTree);
        const flagPreventIndicatorDuplicity = state$.value.App.systemFlags
          .P40_38140_PREVENT_DUPLICITY_OF_INDICATOR_WITHOUT_ASSOCIATED_CHARACTERISTICS as boolean;
        const methodologyDeep = convertToMethodologyDeep({
          methodology: methodologyWithSeason,
          phenomenonsTree,
          company: company.data,
          crops: crops.data.content,
          seasonTree: seasonsTree.data,
          independentVariables: independentVariables.data.content,
          extraDimensions: extraDimensions.data,
          flagPreventIndicatorDuplicity
        });

        if (!methodologyDeep) throw new Error('Error loading methodology');

        if (methodologyDeep.defaultIndicatorsNotFound && methodologyDeep.defaultIndicatorsNotFound.length > 0) {
          methodologyDeep.defaultIndicatorsNotFound.forEach(indicatorNotFound => {
            console.warn(
              'Indicator ID ' +
                indicatorNotFound.indicatorId +
                ' from characteristic ' +
                indicatorNotFound.characteristic.id +
                ', not found in AnalyticContext'
            );
          });
          message.warn('There are default indicators that are not in the analytic context. Check on the console');
        }

        const actionsToReturn = [
          phenomenonActions.loadPhenomenonsTreeSuccess(phenomenonsTree),
          phenomenonActions.loadPhenomenonsTree({ ...initialPageableQuery }, methodology.company_id),
          CompanyActions.loadCompaniesSuccess([company.data], false),
          CropActions.loadCropsSuccess({ page: crops.data, location: 'ALL' }),
          actions.loadMethodologyDeepSuccess(methodologyDeep),
          actions.loadCustomVariables(methodologyDeep),
          variableValidationActions.setVariableValidationList(methodologyDeep?.variablesValidation || [])
        ];

        return of(...actionsToReturn);
      }
    ),
    catchError((error: any) => {
      const msg = notifyErrorOrFallback(error, 'Error loading methodology!');
      return of(actions.loadMethodologyDeepFailure(msg));
    })
  );
};

export const handleLoadCustomVariables: Epic = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_CUSTOM_VARIABLES),
    map((action): MethodologyDeep => action.payload),
    concatMap(methodologyDeep => {
      return getCustomVariables(methodologyDeep.crop!.id!, methodologyDeep.company!.id!);
    }),
    map(response => response.data),
    map((customVariablesDTO: CustomVariablesGetDTO): CustomVariableType[] => {
      return customVariablesDTO.content.map(
        (el): CustomVariableType => ({
          ...el,
          class_name: <AreaVariableType>Object.values(AreaVariableType).find((areaVariable: string) => el.class_name === areaVariable)
        })
      );
    }),
    map(customVariables => actions.loadCustomVariablesSuccess(customVariables))
  );

export const handleSaveMethodologyDeep = (action$: ActionsObservable<Action<SaveMethodologyPayload>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(actions.SAVE_METHODOLOGY_DEEP),
    map((action: Action<SaveMethodologyPayload>) => action.payload ?? ({} as SaveMethodologyPayload)),
    withLatestFrom(
      state$.pipe(
        map(state => ({
          currentUser: state.User.currentUser,
          methodologies: state.App.systemFlags.enableMethodologyReferenceError ? Object.values(state.Methodology.entities) : [],
          seasonsTree: state.App.systemFlags.enableMethodologyReferenceError ? state.Season.seasonsTree : [],
          flagEnabled: state.App.systemFlags.enableMethodologyReferenceError,
          limitCharacteristics: state.App.systemFlags.P40_33556_LIMIT_CHARACTERISTICS_NUMBER,
          clonedVariables: state.MethodologyDeep.clonedVariables,
          clonedIndicators: state.MethodologyDeep.clonedIndicators,
          clonedPhenomena: state.MethodologyDeep.clonedPhenomena
        }))
      )
    ),
    concatMap(([payload, state]) => {
      const { methodologyDeep, plans, showNotification } = payload;

      if (!state.currentUser) {
        const msg = 'Error saving methodology: User not found';
        notification('error', msg);
        return of(actions.saveMethodologyDeepFailure(msg, methodologyDeep));
      }

      if (state.limitCharacteristics) {
        const invalidInspectionGroup = hasInspectionLayoutMoreThanNCharacteristics(1000, methodologyDeep.inspectionLayout);
        if (invalidInspectionGroup) {
          const msg = `Error saving methodology: The inspection group ${getValueLanguage(
            invalidInspectionGroup.name
          )} should not have more than 1000 characteristics`;
          notification('error', msg);
          return of(actions.saveMethodologyDeepFailure(msg, methodologyDeep));
        }
      }

      const realMethodology: Methodology = convertFromMethodologyDeep(methodologyDeep);
      const methodologyToSave: CommandRequest<Methodology | MethodologyUpdatePayload> = buildCommandRequest(
        realMethodology,
        state.currentUser
      );
      const methodologyToSaveOnBase = methodologyToSave.payload.methodology_dto || methodologyToSave.payload;

      return saveMethodology(methodologyToSave, realMethodology.isNew ?? false).pipe(
        concatMap(() => {
          return saveMethodologyOnBase(methodologyToSaveOnBase).pipe(
            concatMap(() => {
              if (showNotification) notification('success', 'Methodology saved!');

              const variableActions = [
                variableValidationActions.hasChangeListVariableValidate(false),
                variableValidationActions.clearVariableValidationToSend(),
                variableValidationActions.setVariableValidationList(
                  methodologyToSave?.payload?.methodology_dto?.inspection_layout?.variables_validation || []
                )
              ];

              const { id: userId, job_title: jobTitle, name: userName } = state.currentUser as User;
              const { id: methodologyId, crop, company } = methodologyDeep;

              trackingEvent('Committed changes', {
                userId,
                jobTitle,
                userName,
                methodologyId,
                cropId: crop?.id,
                cropName: getValueLanguage(crop?.name),
                companyId: company?.id,
                companyName: company?.name,
                clonedVariables: state.clonedVariables,
                clonedIndicators: state.clonedIndicators,
                clonedPhenomena: state.clonedPhenomena
              });

              let actionsToReturn: unknown[] = [
                methodologyActions.updateMethodology(realMethodology),
                actions.resetClonedPhenomenaTotals()
              ];
              if (methodologyDeep.company?.id === templateCompanyId && realMethodology.id && plans) {
                return concat([
                  ...actionsToReturn,
                  actions.saveMethodologyDeepSuccess(methodologyDeep),
                  methodologyTemplateActions.saveMethodologyPlans(realMethodology.id, plans),
                  ...variableActions
                ]);
              }
              actionsToReturn = [...actionsToReturn, actions.saveMethodologyDeepSuccess(methodologyDeep), ...variableActions];
              return of(...actionsToReturn);
            }),
            catchError((error: any) => {
              const msg = state.flagEnabled
                ? notifyMethodologyErrorOrFallback(error, 'Error save methodology on base!', state.methodologies, state.seasonsTree)
                : notifyErrorOrFallback(error, 'Error save methodology on base!');

              const actionsToReturn: unknown[] = [actions.saveMethodologyDeepFailure(getMessageError(msg), methodologyDeep)];

              if (methodologyDeep.id) actionsToReturn.push(actions.loadMethodologyDeep(methodologyDeep.id));

              return of(...actionsToReturn);
            })
          );
        }),
        catchError((error: any) => {
          const msg = showNotification
            ? notifyErrorOrFallback(error, 'Error save methodology on Demeter!')
            : 'Error save methodology on Demeter!';
          return of(actions.saveMethodologyDeepFailure(getMessageError(msg)));
        })
      );
    })
  );

export const handleSaveMethodologyDeepSuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_METHODOLOGY_DEEP_SUCCESS),
    map((action: Action<any>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.MethodologyDeep.addMethodologyModalActive;
        })
      )
    ),
    concatMap(([methodologyDeep, addMethodologyModalActive]: any) => {
      if (addMethodologyModalActive) {
        return concat([
          actions.toggleAddMethodologyDeepModal(false),
          actions.changeTabActive('2'),
          actions.saveMethodologyDeepSuccess(methodologyDeep)
        ]);
      }
      return of();
    })
  );

export const handleReorderMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.REORDER_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    map((payload: any) => {
      const methodologyDeep = reorderMethodologyDeep(state$.value.MethodologyDeep.methodologyDeep, payload);
      return actions.reorderMethodologyDeepSuccess(methodologyDeep);
    }),
    catchError((error: any) => {
      return of(actions.reorderMethodologyDeepFailure(error));
    })
  );

export const handleUpdateCollapsedMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    map((payload: any) => {
      const methodologyDeep = getUpdatedMethodology(state$.value.MethodologyDeep.methodologyDeep, payload);
      return actions.updateCollapsedMethodologyDeepSuccess(methodologyDeep);
    }),
    catchError((error: any) => {
      return of(actions.updateCollapsedMethodologyDeepFailure(error));
    })
  );

export const toggleDefaultMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.TOGGLE_DEFAULT_METHODOLOGY_DEEP),
    map(() => {
      const methodologyDeep = toggleDefaultMethodology(state$.value.MethodologyDeep.methodologyDeep);
      return actions.updateMethodologyDeep(methodologyDeep);
    })
  );

export const handleUpdateBasicInformation = (action$, state$): any =>
  action$.pipe(
    ofType(actions.UPDATE_METHODOLOGY_DEEP_BASIC_INFORMATION),
    map((action: Action<any>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.MethodologyDeep.methodologyDeep;
        })
      )
    ),
    concatMap(([payload, methodologyDeep]: any) => {
      return of(actions.updateMethodologyDeep(updateBasicInformation(payload, methodologyDeep)));
    })
  );

export const handleDeleteIndependentVariableFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP),
    map((action: Action<object>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep))),
    concatMap(([variable, methodologyDeep]: any) => {
      const usedIds = getCharacteristicIdsUsedInMethodology(getMethodologyDeepComponentDeep(methodologyDeep));

      if (usedIds.includes(variable.id)) {
        message.error('There are indicators using this variable!');
        return of(actions.deleteIndependentVariableFromMethodologyDeepFailure('Variable in use in another indicator'));
      }

      const updatedMethodology: MethodologyDeep = {
        ...methodologyDeep,
        inspectionLayout: {
          ...methodologyDeep.inspectionLayout,
          independentVariables: methodologyDeep.inspectionLayout.independentVariables.filter(v => v.id !== variable.id)
        }
      };
      notification('success', 'Deleted!');
      return of(actions.deleteIndependentVariableFromMethodologyDeepSuccess(updatedMethodology));
    }),
    catchError(err => {
      return of(actions.deleteIndependentVariableFromMethodologyDeepFailure(err));
    })
  );

export const handleDeleteCharacteristicIndicatorFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP),
    map((action: Action<object>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep))),
    concatMap(([item, methodologyDeep]: any) => {
      const flagPreventIndicatorDuplicity = state$.value.App.systemFlags
        .P40_38140_PREVENT_DUPLICITY_OF_INDICATOR_WITHOUT_ASSOCIATED_CHARACTERISTICS as boolean;
      const { mutableMethodology, indicatorsThatReferenceCharacteristic } = checkIfCharacteristicIsUsedAndRemoveFromMethodology(
        methodologyDeep,
        item,
        flagPreventIndicatorDuplicity
      );

      if (indicatorsThatReferenceCharacteristic.length) {
        message.error('The following indicators use this characteristic: ' + JSON.stringify(indicatorsThatReferenceCharacteristic));
        return of(actions.deleteCharacteristicIndicatorFromMethodologyDeepFailure('Characteristic in use in another indicator'));
      }

      notification('success', 'Deleted!');
      return of(actions.deleteCharacteristicIndicatorFromMethodologyDeepSuccess(mutableMethodology));
    })
  );

const findInformationOnPhenomenon = (
  inspectionGroupElement: InspectionGroupDeep,
  categoryElement: CategoryDeep,
  phenomenonElement: PhenomenonDeep,
  item: SimpleCharacteristicsIndicatorsProps
) => {
  let phenomenon: PhenomenonDeep | undefined;
  let category: CategoryDeep | undefined;
  let inspectionGroup: InspectionGroupDeep | undefined;
  let characteristic: CharacteristicDeep | undefined;

  /* istanbul ignore else */
  if (phenomenonElement.components && phenomenonElement.phenomenonId === item.phenomenonId) {
    const componentFound = phenomenonElement.components.find(
      component => component.characteristic && component.characteristic.characteristicId === item.characteristicId
    );
    /* istanbul ignore else */
    if (componentFound) {
      characteristic = componentFound.characteristic;
      phenomenon = phenomenonElement;
      category = categoryElement;
      inspectionGroup = inspectionGroupElement;
      return { characteristic, phenomenon, category, inspectionGroup };
    }
  }

  return undefined;
};

const findPhenomenonOnCategory = (
  inspectionGroupElement: InspectionGroupDeep,
  categoryElement: CategoryDeep,
  item: SimpleCharacteristicsIndicatorsProps
) => {
  /* istanbul ignore else */
  if (categoryElement.phenomenons) {
    for (let phenomenonElement of categoryElement.phenomenons) {
      const information = findInformationOnPhenomenon(inspectionGroupElement, categoryElement, phenomenonElement, item);
      if (information) {
        return information;
      }
    }
  }
  return undefined;
};

const findPhenomenonOnInpectionGroup = (inspectionGroupElement: InspectionGroupDeep, item: SimpleCharacteristicsIndicatorsProps) => {
  /* istanbul ignore else */
  if (inspectionGroupElement.categories) {
    for (let categoryElement of inspectionGroupElement.categories) {
      const information = findPhenomenonOnCategory(inspectionGroupElement, categoryElement, item);
      if (information) {
        return information;
      }
    }
  }
  return undefined;
};

const findPhenomenonOnMethodologyDeep = (methodologyDeep: MethodologyDeep, item: SimpleCharacteristicsIndicatorsProps) => {
  /* istanbul ignore else */
  if (methodologyDeep.inspectionLayout) {
    for (let inspectionGroupElement of methodologyDeep.inspectionLayout.inspectionGroups) {
      const information = findPhenomenonOnInpectionGroup(inspectionGroupElement, item);
      if (information) {
        return information;
      }
    }
  }
  return undefined;
};

export const handleDeleteOnlyIndicatorFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_ONLY_INDICATOR_FROM_METHODOLOGY_DEEP),
    map((action: Action<object>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => [state.MethodologyDeep.methodologyDeep, state.Phenomenon.phenomenonsTree]))),
    concatMap(([item, stateItems]: [SimpleCharacteristicsIndicatorsProps, [MethodologyDeep, Phenomenon[]]]) => {
      const [methodologyDeep, phenomenonsTree] = stateItems;
      const clonedMethodology = cloneDeep(methodologyDeep);

      const phenomenonInformation = findPhenomenonOnMethodologyDeep(clonedMethodology, item);
      if (!phenomenonInformation) {
        throw new Error('Not found phenomenon to remove indicator.');
      }

      const { characteristic, phenomenon } = phenomenonInformation;

      let characteristicToChange: Characteristic;
      phenomenonsTree.forEach(phenomenonFromTree => {
        /* istanbul ignore else */
        if (phenomenonFromTree.characteristics) {
          const found = phenomenonFromTree.characteristics.find(el => el.id === item.characteristicId);
          /* istanbul ignore else */
          if (found && found.default_indicator_id) {
            characteristicToChange = { ...found, default_indicator_id: undefined };
            return;
          }
        }
      });

      return of(
        characteristicActions.saveCharacteristic({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          characteristic: characteristicToChange!,
          methodologyId: methodologyDeep.id,
          orderedComponentId: characteristic?.id,
          orderedPhenomenonId: phenomenon.id
        })
      );
    })
  );

export const handleUpdateCharacteristicFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_CHARACTERISTIC_FROM_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep)),
      state$.pipe(map((state: any) => state.MethodologyDeep.addCharacteristicIndicatorActive))
    ),
    concatMap(([action, methodologyDeep, addCharacteristicIndicatorActive]: any) => {
      const { characteristic, methodologyId, orderedComponentId, orderedPhenomenonId, defaultIndicator } = action;

      if (methodologyDeep && (!characteristic.phenomenon_id || !characteristic.phenomenon_id.length)) {
        const newMethodologyDeep: MethodologyDeep = {
          ...methodologyDeep,
          inspectionLayout: {
            ...methodologyDeep.inspectionLayout,
            independentVariables:
              methodologyDeep.inspectionLayout && methodologyDeep.inspectionLayout.independentVariables
                ? [...methodologyDeep.inspectionLayout.independentVariables.filter(v => v.id !== characteristic.id), characteristic]
                : [characteristic]
          }
        };
        const arrayActions: any = [
          characteristicActions.saveCharacteristicSuccess(characteristic),
          actions.updateMethodologyDeep(newMethodologyDeep)
        ];
        return concat(arrayActions);
      }

      if (methodologyId && methodologyDeep && methodologyId === methodologyDeep.id) {
        if (defaultIndicator) {
          defaultIndicator.expression = {
            characteristics: [characteristic.id]
          };
        }
        methodologyDeep = addOrUpdateInMethodologyDeepByCharacteristic(
          characteristic,
          defaultIndicator,
          methodologyDeep,
          orderedComponentId,
          orderedPhenomenonId
        );

        const arrayActions: any = [
          characteristicActions.saveCharacteristicSuccess(characteristic),
          actions.updateMethodologyDeep(methodologyDeep)
        ];

        if (addCharacteristicIndicatorActive) {
          arrayActions.push(actions.toggleAddCharacteristicIndicatorModal());
        }

        return concat(arrayActions);
      }

      return of(characteristicActions.saveCharacteristicSuccess(characteristic));
    })
  );

export const handleAddPhenomenonToMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.App.systemFlags))),
    exhaustMap(([{ phenomenaIds, inspectionGroupId }, systemFlags]: any) => {
      const phenomenonsTree: Phenomenon[] = state$.value.Phenomenon.phenomenonsTree;
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      const companyId = methodologyDeep.company!.id!;
      let phenomenaIdsToClone = getCanonicalIdsToClone(phenomenaIds, phenomenonsTree);

      if (phenomenaIdsToClone.length > 0) {
        let sources: AxiosObservable<Phenomenon>[] = [];
        phenomenaIdsToClone.forEach(phenomenonIdToClone => {
          sources.push(clonePhenomenon(phenomenonIdToClone, companyId, systemFlags.enableCottonGrowth));
        });

        const phenomenaWithoutCanonicalReferences = phenomenaIds.filter(id => !phenomenaIdsToClone.includes(id));

        return forkJoin([
          of({
            phenomenaIds: phenomenaWithoutCanonicalReferences,
            inspectionGroupId
          }),
          ...sources
        ]);
      }
      return of([{ phenomenaIds, inspectionGroupId }]);
    }),
    map((results: any[]) => {
      const updatedPhenomenonsTree: Phenomenon[] = state$.value.Phenomenon.phenomenonsTree;

      const { phenomenaIds, inspectionGroupId } = results[0];

      if (results.length > 1) {
        results.forEach((result, index) => {
          if (index > 0) {
            phenomenaIds.push(result.data.id);
            updatedPhenomenonsTree.push(result.data);
          }
        });
      }

      return { phenomenaIds, inspectionGroupId, updatedPhenomenonsTree };
    }),
    concatMap(({ phenomenaIds, inspectionGroupId, updatedPhenomenonsTree }: any) => {
      const categoriesWithPhenomena = createCategoryWithPhenomena(phenomenaIds, updatedPhenomenonsTree);

      const methodologyDeep = addCategoryPhenomenaToMethodology(
        state$.value.MethodologyDeep.methodologyDeep,
        inspectionGroupId,
        categoriesWithPhenomena
      );

      return of(
        actions.addPhenomenaMethodologyDeepSuccess(methodologyDeep),
        phenomenonActions.loadPhenomenonsTreeSuccess(updatedPhenomenonsTree)
      );
    }),
    catchError((error: any, caught) => {
      const msg = notifyErrorOrFallback(error, 'Phenomenon could not be added to methodology');
      return merge(of(actions.addPhenomenaMethodologyDeepFailure(msg)), caught);
    })
  );

export const handleAddExtraDimensionToMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    exhaustMap((payload: any) => {
      const extraDimensions: ExtraDimension[] = state$.value.ExtraDimensions.entities;
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      return of({
        inspectionGroupId: payload.inspectionGroupId,
        methodologyDeep: methodologyDeep,
        extraDimensions: Object.values(extraDimensions).filter(ex => payload.extraDimensionIds.find(ids => ex.id === ids))
      });
    }),
    concatMap(({ inspectionGroupId, methodologyDeep, extraDimensions }: any) => {
      const newMethodologyDeep = addExtraDimensionToMethodology(methodologyDeep, inspectionGroupId, Object.values(extraDimensions));
      return of(actions.addExtraDimensionMethodologyDeepSuccess(newMethodologyDeep));
    }),
    catchError((error: any, caught) => {
      const msg = notifyErrorOrFallback(error, 'Resource could not be added to methodology');
      return merge(of(actions.addExtraDimensionMethodologyDeepFailure(msg)), caught);
    })
  );

export const handleDeleteExtraDimensionToMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    exhaustMap((payload: any) => {
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      return of({
        methodologyDeep: methodologyDeep,
        extraDimensionId: payload.extraDimensionId,
        inspectionGroupId: payload.inspectionGroupId
      });
    }),
    concatMap(({ methodologyDeep, extraDimensionId, inspectionGroupId }: any) => {
      const newMethodologyDeep = removeExtraDimensionFromMethodology(methodologyDeep, extraDimensionId, inspectionGroupId);
      return of(actions.addExtraDimensionMethodologyDeepSuccess(newMethodologyDeep));
    }),
    catchError((error: any, caught) => {
      const msg = notifyErrorOrFallback(error, 'Resource could not be deleted to methodology');
      return merge(of(actions.addExtraDimensionMethodologyDeepFailure(msg)), caught);
    })
  );

export const handleUpsertInspectionGroup = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPSERT_INSPECTION_GROUP),
    map((action: Action<object>) => action.payload),
    concatMap(({ inspectionGroupName, inspectionGroupId }) => {
      const currentMethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;

      const newMethodologyDeep = upsertInspectionGroup(currentMethodologyDeep, {
        inspectionGroupName,
        inspectionGroupId
      });
      return of(actions.updateMethodologyDeep(newMethodologyDeep), actions.toggleInspectionGroupModal());
    }),
    catchError((error: any) => {
      const msg = notifyErrorOrFallback(error, 'Error on creating inspection group for methodology');
      return of(actions.upsertInspectionGroupFailure(msg));
    })
  );

export const handleDeleteInspectionGroupMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_INSPECTION_GROUP),
    map((action: Action<UUID>) => action.payload),
    concatMap((inspectionGroupId: UUID) => {
      const currentMethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;

      const newMethodologyDeep = deleteInspectionGroup(currentMethodologyDeep, inspectionGroupId);
      notification('success', 'Inspection group was deleted from methodology!');
      return of(actions.updateMethodologyDeep(newMethodologyDeep), actions.toggleInspectionGroupModal());
    }),
    catchError((error: any) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error deleting inspection group for methodology');
      return of(actions.deleteInspectionGroupMethodologyDeepFailure(errorMsg));
    })
  );

export const handleDeletePhenomenonFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP),
    map((action: Action<UUID>) => action.payload),
    map((phenomenonId: UUID) => {
      const methodologyDeep = removePhenomenonFromMethodology(state$.value.MethodologyDeep.methodologyDeep, phenomenonId);
      return actions.deletePhenomenonFromMethodologyDeepSuccess(methodologyDeep);
    }),
    catchError((error: any) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error on removing phenomenon from methodology');
      return of(actions.deletePhenomenonFromMethodologyDeepFailure(errorMsg));
    })
  );

function getCharacteristicsFromPhenomenaTree(phenomena: Phenomenon[]) {
  return phenomena
    .filter(phenomenon => phenomenon.characteristics && phenomenon.characteristics.length > 0)
    .flatMap(phenomenon => phenomenon.characteristics!);
}

function getIndicatorsFromPhenomenaTree(phenomena: Phenomenon[]) {
  return phenomena
    .filter(phenomenon => phenomenon.indicators && phenomenon.indicators.length > 0)
    .flatMap(phenomenon => phenomenon.indicators!);
}

export const getCloningObservables = (phenomena: Phenomenon[], selectedComponents, companyId: string, enableCottonGrowth?: boolean) => {
  let sources: any[] = [];
  const characteristicsTree = getCharacteristicsFromPhenomenaTree(phenomena);
  const indicatorsTree = getIndicatorsFromPhenomenaTree(phenomena);

  let characteristicIdsToClone = getCanonicalIdsToClone(selectedComponents, characteristicsTree);
  const indicatorIdsToClone = getCanonicalIdsToClone(selectedComponents, indicatorsTree);

  if (characteristicIdsToClone?.length && indicatorIdsToClone?.length) {
    const indicatorsComplete = indicatorsTree.filter(indicator => indicatorIdsToClone.includes(indicator.id as string));
    characteristicIdsToClone = characteristicIdsToClone.filter(characteristicId => {
      const indicator = indicatorsComplete.some(indicatorFull => indicatorFull?.expression?.characteristics?.includes(characteristicId));
      return !indicator;
    });
  }

  if (characteristicsTree) {
    if (characteristicIdsToClone.length > 0) {
      characteristicIdsToClone.forEach(characteristicIdToClone => {
        sources.push(cloneCharacteristic(characteristicIdToClone, companyId, enableCottonGrowth));
      });
    }
  }

  if (indicatorsTree) {
    if (indicatorIdsToClone.length > 0) {
      indicatorIdsToClone.forEach(indicatorIdToClone => {
        sources.push(cloneIndicator(indicatorIdToClone, companyId, enableCottonGrowth));
      });
    }
  }

  return sources;
};

export function getLoadIndicatorsObservables(indicatorIds: UUID[]) {
  return indicatorIds.map(indicatorId => getIndicator(indicatorId));
}

export function getCharacteristicsCloned(characterisIds: UUID[]) {
  return characterisIds.map(characterisId => getCharacteristic(characterisId));
}

export const getUpdatedPhenomenaTree = (results, clonedComponents, updatedPhenomenonsTree, selectedComponents) => {
  let phenomenaTreeUpdated = updatedPhenomenonsTree;
  const phenomenaTree = results[1]?.data;

  if (results.length > 2) {
    clonedComponents.push(...results.slice(2).map(result => result.data));
  }

  if (clonedComponents) {
    phenomenaTreeUpdated = upsertComponentsIntoPhenomenon(clonedComponents, phenomenaTreeUpdated, phenomenaTree);
  }

  const selectedComponentsMutable = selectedComponents.map(componentId => {
    if (clonedComponents && clonedComponents.some(c => c.parent_id === componentId)) {
      const component = clonedComponents.find(c => c.parent_id === componentId);
      return component.id;
    }
    return componentId;
  });

  return { selectedComponentsMutable, phenomenaTreeUpdated };
};

export const loadObservablesCloned = results => {
  let loadIndicatorObservables: AxiosObservable<Indicator>[] = [];
  let loadCharacteristicObservables: AxiosObservable<Characteristic>[] = [];
  let clonedComponents;
  if (results.length > 1) {
    clonedComponents = results.slice(1).map(result => result.data);
    const defaultIndicatorIds = clonedComponents
      .filter(component => !!component.default_indicator_id)
      .map(component => component.default_indicator_id);

    let characteriscticIds = clonedComponents
      .filter((component: Indicator) => !!component.expression?.characteristics?.length)
      .map(component => component.expression?.characteristics);

    if (defaultIndicatorIds && defaultIndicatorIds.length > 0) {
      loadIndicatorObservables = getLoadIndicatorsObservables(defaultIndicatorIds);
    }

    if (characteriscticIds && characteriscticIds.length > 0) {
      characteriscticIds = [...new Set(characteriscticIds.flatMap(id => id))];
      loadCharacteristicObservables = getCharacteristicsCloned(characteriscticIds);
    }
  }
  return { loadIndicatorObservables, loadCharacteristicObservables, clonedComponents };
};

export const handleAddCharacteristicsIndicatorsToMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.App.systemFlags))),
    exhaustMap(([payload, systemFlags]: any) => {
      const { selectedComponents, orderedPhenomenonId, phenomenonId } = payload;
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      const companyId = methodologyDeep.company!.id!;
      const phenomena: Phenomenon[] = state$.value.Phenomenon.phenomenonsTree;
      const phenomenon: Phenomenon = findObjectById(phenomena, phenomenonId);

      let sources: any[] = [];
      if (!isObjectReferenceCanonical(phenomenon)) {
        sources = getCloningObservables(phenomena, selectedComponents, companyId, systemFlags.enableCottonGrowth);
      }

      return forkJoin([of({ selectedComponents, orderedPhenomenonId, phenomenonId }), ...sources]);
    }),
    concatMap((results: any[]) => {
      const { selectedComponents, orderedPhenomenonId, phenomenonId } = results[0];

      const phenomenaTree = [getPhenomenon(phenomenonId)];

      const { loadIndicatorObservables, loadCharacteristicObservables, clonedComponents } = loadObservablesCloned(results);

      return forkJoin([
        of({ selectedComponents, orderedPhenomenonId, clonedComponents, phenomenonId }),
        ...phenomenaTree,
        ...loadIndicatorObservables,
        ...loadCharacteristicObservables
      ]);
    }),
    map((results: any) => {
      const { selectedComponents, orderedPhenomenonId, clonedComponents, phenomenonId } = results[0];
      const updatedPhenomenonsTree: Phenomenon[] = state$.value.Phenomenon.phenomenonsTree;

      const { selectedComponentsMutable, phenomenaTreeUpdated } = getUpdatedPhenomenaTree(
        results,
        clonedComponents,
        updatedPhenomenonsTree,
        selectedComponents
      );

      return {
        selectedComponents: selectedComponentsMutable,
        orderedPhenomenonId,
        updatedPhenomenonsTree: phenomenaTreeUpdated,
        phenomenonId
      };
    }),
    concatMap(({ selectedComponents, orderedPhenomenonId, updatedPhenomenonsTree, phenomenonId }: any) => {
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      const phenomenon: Phenomenon = findObjectById(updatedPhenomenonsTree, phenomenonId);
      const componentsDeep: ComponentDeep[] = createComponentDeepFromPhenomenonTree(phenomenon);
      const componentsToAdd: ComponentDeep[] = componentsDeep.filter(component => {
        if (component.characteristic && component.characteristic.characteristicId)
          return selectedComponents.includes(component.characteristic.characteristicId);
        if (component.indicator && component.indicator.indicatorId) return selectedComponents.includes(component.indicator.indicatorId);
      });

      const updatedMethodologyDeep: MethodologyDeep = insertComponentsOnMethodologyDeep(
        methodologyDeep,
        orderedPhenomenonId,
        componentsToAdd
      );

      const componentActions = [
        actions.addCharacteristicIndicatorsSuccess(updatedMethodologyDeep),
        actions.toggleAddCharacteristicIndicatorModal()
      ];

      if (updatedPhenomenonsTree) {
        componentActions.push(phenomenonActions.loadPhenomenonsTreeSuccess(updatedPhenomenonsTree));
      }

      return concat(componentActions);
    }),
    catchError((error: any, caught) => {
      const errorMsg = notifyErrorOrFallback(error, '');
      return merge(of(actions.addCharacteristicsIndicatorsFailure(errorMsg)), caught);
    })
  );

export const handleToggleShowOnInspectionLayout = (action$, state$) =>
  action$.pipe(
    ofType(actions.TOGGLE_SHOW_ON_INSPECTION_LAYOUT),
    map((action: Action<UUID>) => action.payload),
    concatMap((id: UUID) => {
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      const updatedMethodologyDeep: MethodologyDeep = toggleShowFlag(methodologyDeep, id, 'inspLayout');
      return of(actions.updateMethodologyDeep(updatedMethodologyDeep));
    }),
    catchError((error: any) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error on toggle');
      return of(actions.toggleShowOnInspectionLayoutFailure(errorMsg));
    })
  );

export const handleToggleShowOnTimeline = (action$, state$) =>
  action$.pipe(
    ofType(actions.TOGGLE_SHOW_ON_TIMELINE),
    map((action: Action<UUID>) => action.payload),
    concatMap((id: UUID) => {
      const methodologyDeep: MethodologyDeep = state$.value.MethodologyDeep.methodologyDeep;
      const updatedMethodologyDeep: MethodologyDeep = toggleShowFlag(methodologyDeep, id, 'timeline');
      return of(actions.updateMethodologyDeep(updatedMethodologyDeep));
    }),
    catchError((error: any) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error on toggle');
      return of(actions.toggleShowOnTimelineFailure(errorMsg));
    })
  );
export const handleRemoveMultPhenonIndicatorFromMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP),
    map((action: Action<any>) => action.payload),
    map((indicatorId: UUID) => {
      const methodologyDeep: MethodologyDeep = removeMultPhenonIndicatorMethodologyDeep(
        state$.value.MethodologyDeep.methodologyDeep,
        indicatorId
      );
      notification('success', 'Indicator Mult Phenon removed!');
      return actions.removeMultPhenonIndicatorFromMethodologyDeepSuccess(methodologyDeep);
    }),
    catchError((error: ErrorMsg) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error remove Indicator Mult Phenon!');
      return of(actions.removeMultPhenonIndicatorFromMethodologyDeepFailure(errorMsg));
    })
  );

export const handleCloneMethodologyDeep = (action$, state$) =>
  action$.pipe(
    ofType(actions.CLONE_METHODOLOGY),
    map((action: Action<any>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.User.currentUser)),
      state$.pipe(map((state: any) => state.App.systemFlags)),
      state$.pipe(
        map((state: AppState) => (state.App.systemFlags.enableMethodologyReferenceError ? Object.values(state.Methodology.entities) : []))
      ),
      state$.pipe(map((state: AppState) => (state.App.systemFlags.enableMethodologyReferenceError ? state.Season.seasonsTree : []))),
      state$.pipe(map((state: AppState) => state.App.systemFlags.enableMethodologyReferenceError))
    ),
    concatMap(([actionPayload, currentUser, systemFlags, methodologies, seasonsTree, flagEnabled]: any) => {
      const { methodologyDeep } = actionPayload;
      const realMethodology: Methodology = convertFromMethodologyDeep(methodologyDeep);
      realMethodology.isNew = false;
      const methodologyToSave: CommandRequest<Methodology | MethodologyUpdatePayload> = buildCommandRequest(realMethodology, currentUser);
      const methodologyToSaveOnBase = methodologyToSave.payload.methodology_dto || methodologyToSave.payload;
      return cloneMethodology(methodologyToSave, methodologyDeep.id, methodologyDeep.company.id, systemFlags.enableCottonGrowth).pipe(
        concatMap(response =>
          saveMethodologyOnBase({ ...methodologyToSaveOnBase, id: response.data.id }).pipe(
            concatMap(() => {
              notification('success', 'Methodology cloned!');
              if (response.data.id) {
                if (actionPayload.plans) {
                  return of(
                    actions.loadMethodologyDeep(response.data.id),
                    actions.cloneMethodologyDeepSuccess(),
                    methodologyTemplateActions.saveMethodologyPlans(response.data.id, actionPayload.plans)
                  );
                }

                return of(actions.loadMethodologyDeep(response.data.id), actions.cloneMethodologyDeepSuccess());
              }
              return of(actions.cloneMethodologyDeepSuccess());
            }),
            catchError((error: ErrorMsg) => {
              if (flagEnabled) {
                const errorMsg = notifyMethodologyErrorOrFallback(error, 'Error clone methodology on base!', methodologies, seasonsTree);
                if (!response.data.id) {
                  return of(actions.cloneMethodologyDeepFailure(getMessageError(errorMsg)));
                }
                return of(actions.cloneMethodologyDeepFailure(getMessageError(errorMsg)), actions.loadMethodologyDeep(response.data.id));
              }
              const msg = notifyErrorOrFallback(error, 'Error clone methodology on base!');
              return of(actions.cloneMethodologyDeepFailure(getMessageError(msg)));
            })
          )
        ),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error clone methodology on Demeter!');
          return of(actions.cloneMethodologyDeepFailure(getMessageError(errorMsg)));
        })
      );
    })
  );
