import _, { differenceBy, isEmpty } from 'lodash';
import React from 'react';
import {
  RBACRole,
  jobTitleWithAccessServiceProvider,
  jobTitleWithAccessToMultiOrgSelect,
  rolesAdminLevel,
  rolesHasContentTypeApp,
  rolesHasPropertyId,
  rolesLevelOrg,
  rolesOption,
  rolesUsedOnlyInAdmin
} from '../../components/inviteUserByEmailModalRbac/utils/rbacRoles';
import { protectorAppId } from '../../redux/cropwisePlans/models';
import {
  RBACGetWorkspaceOperationsResponse,
  RBACMakeResourceByLevelProps,
  RBACPermissionTypesEnum,
  RBACWorkspaceOperations,
  RBACParsedOperations,
  RBACOperations,
  RBACLevels,
  CreateOperationByRBACLevels,
  GetAllRolesByUserResponse,
  AllRolesByUserParsed,
  RBACFormatToSendProps,
  UpdateUserRolesRBACRequestParams,
  ActionTypesEnum,
  ListOrganizationProvider,
  CreateRegularInviteParams,
  RBACQueryType,
  EditRegularInviteParams,
  PayloadInviteRBACProps,
  CreateManagedInviteParams,
  EditManagedInviteParams,
  InviteControleCertoParams,
  CreateControleCertoInviteParams,
  EditControleCertoInviteParams,
  GenerateInputsForBasicInfoDataProps,
  RemoveOrAddRolesForMultipleOrgsProps,
  RBACActionsEnum,
  ContentRolesRBAC,
  RolesLevelAdminEnum,
  GetChangedFieldsProps
} from './rbac.query.model';
import { InputForm, InputType, Option, SizeEnum } from '../../components/formBuilder/models';
import STTypo from '../../components/typo/STTypo';
import { ColorOptions } from '../../components/typo/STTypo.models';
import { OrgUser, UserTypeEnum } from '../../redux/models';
import { notification } from '../../components';
import Actions from '../../redux/actions';
import { createRoles } from './rbac.adapters';
import { queryClient } from '../../containers/App/App';
import { store } from '../../redux/store';
import {
  createUserControleCertoV2,
  createUserManagerV2,
  editAccountInvitationV2,
  editRolesRBACByUser,
  getOrganizationInfo,
  getUserDataByEmailV2,
  sendAccountInvitationV2
} from './rbac.services';
import { MAX_ROLES_SENT, MAX_ROLES_SENT_INVITE_REGULAR, allTypesInputs } from './rbac.utils';
import moment from 'moment';
import { Dispatch } from 'redux';

const { updateValueIsSaving, toggleInviteUserByEmailModal, toggleAddEditUserModal } = Actions;
const webPainelUrl = process.env.REACT_APP_URL_WEB_PAINEL;

const dispatchInitialValuesToModals = (dispatch: Dispatch) => {
  dispatch(toggleInviteUserByEmailModal(false));
  dispatch(toggleAddEditUserModal({ open: false }));
  dispatch(updateValueIsSaving(false));
};

export const processOperation = (accumulator: RBACWorkspaceOperations, operation: RBACOperations): RBACWorkspaceOperations => {
  const { action: operationAction, resource: operationResource } = operation;
  const [role, action] = operationAction.split(':') as [string, RBACPermissionTypesEnum];

  if (!accumulator?.[operationResource]) {
    accumulator[operationResource] = {};
  }

  if (!accumulator?.[operationResource]?.[role]) {
    accumulator[operationResource][role] = [];
  }

  if (!accumulator?.[operationResource]?.[role].includes(action)) {
    accumulator[operationResource][role].push(action);
  }

  return accumulator;
};

export const parseWorkspaceOperations = (operations?: RBACGetWorkspaceOperationsResponse): RBACParsedOperations | undefined => {
  if (!operations || isEmpty(operations)) return undefined;

  const { allowed_operations, denied_operations } = operations;

  const allowedOperations = allowed_operations?.reduce(processOperation, {} as RBACWorkspaceOperations);
  const deniedOperations = denied_operations?.reduce(processOperation, {} as RBACWorkspaceOperations);

  return {
    allowed_operations: allowedOperations,
    denied_operations: deniedOperations
  };
};

export const setUpResourceByLevel = ({ level, workspaceId, companyId, propertyId, appId }: RBACMakeResourceByLevelProps): string => {
  switch (level) {
    case RBACLevels.WORKSPACE:
      return `crn:workspace:${workspaceId}`;
    case RBACLevels.ORG:
      return `crn:workspace:${workspaceId}/org/${companyId}`;
    case RBACLevels.PROPERTY:
      return `crn:workspace:${workspaceId}/org/${companyId}/property/${propertyId}`;
    case RBACLevels.SYSTEM:
      return 'crn:system';
    case RBACLevels.APP:
      return `crn:app:${appId}`;
    case RBACLevels.USER:
      return `crn:app:${appId}`;
    default:
      return `crn:workspace:${workspaceId}`;
  }
};

export function createOperation({
  action,
  level,
  permission,
  workspaceId,
  companyId,
  propertyId,
  appId
}: CreateOperationByRBACLevels): RBACOperations {
  return {
    action: `${action}:${permission}`,
    resource: setUpResourceByLevel({
      level,
      workspaceId,
      companyId,
      propertyId,
      appId
    })
  };
}

export const formatAllRolesByUser = (data: GetAllRolesByUserResponse['content']): AllRolesByUserParsed => {
  return (data || []).reduce((acc, item) => {
    const level = item?.level.toLowerCase();
    const role = item.role_id;
    const resource = item.resource_attachment;

    if (!acc[level]) {
      acc[level] = {};
    }

    acc[level][role] = {
      ...(acc[level][role] || {}),
      resource
    };

    return acc;
  }, {} as AllRolesByUserParsed);
};

export const createObjRoleProperty = ({
  acc,
  level,
  role,
  propertyID,
  resource
}: {
  acc: AllRolesByUserParsed;
  level: string;
  role: string;
  propertyID: string;
  resource: string;
}) => {
  acc[level][`${role}-${propertyID}`] = {
    ...(acc[level][`${role}${propertyID}`] || {}),
    resource,
    role_id: role
  };
};

export const formatAllRolesAllowedByUser = (
  data: GetAllRolesByUserResponse['content'],
  orgId: string,
  workspaceId
): AllRolesByUserParsed => {
  return (data || []).reduce((acc, item) => {
    const level = item?.level.toLowerCase();
    const role = item.role_id;
    const resource = item.resource_attachment;
    const propertyID = item?.resource_attachment?.split('property/')[1];
    const roleIsProperty = role === RBACRole.PropertyAdministrator || role === RBACRole.PropertyCollaborator;

    if (!acc[level]) {
      acc[level] = {};
    }

    if (role === RBACRole.WorkspaceOwner && resource.includes(workspaceId)) {
      acc[level][role] = {
        ...(acc[level][role] || {}),
        resource
      };
    }

    if (resource.includes(orgId) && roleIsProperty) {
      createObjRoleProperty({ acc, level, role, propertyID, resource });
    }

    if (resource.includes(orgId)) {
      if (roleIsProperty) {
        createObjRoleProperty({ acc, level, role, propertyID, resource });
      } else {
        acc[level][role] = {
          ...(acc[level][role] || {}),
          resource
        };
      }
    }

    return acc;
  }, {} as AllRolesByUserParsed);
};

export const getValueAfterWord = (resource: string, value: string) => resource.split(value)[1];

export const formatAllRolesReceivedToRolesToSend = (data: GetAllRolesByUserResponse['content'], orgId, workspaceID) => {
  return (data || []).reduce((acc, item) => {
    const resource = item.resource_attachment;
    const role = item.role_id;
    const propertyID = item?.resource_attachment?.split('property/')[1];

    if (resource.includes(orgId)) {
      acc.push({
        org_id: orgId,
        role_context: rolesHasContentTypeApp.includes(role) ? 'system' : `app:${protectorAppId}`,
        ...(rolesHasPropertyId.includes(role) && { property_id: propertyID }),
        role_id: role,
        workspace_id: workspaceID
      });
    }

    if (resource.includes(workspaceID) && role === RBACRole.WorkspaceOwner) {
      acc.push({
        role_context: item.context,
        role_id: role,
        workspace_id: workspaceID
      });
    }

    return acc;
  }, Array<RBACFormatToSendProps>());
};

export const adapterAllRolesAllowedByUserForDiff = (
  data: GetAllRolesByUserResponse['content'],
  orgId: string,
  workspace_id: string,
  hasPropertyRoles = false
): RBACFormatToSendProps[] => {
  const onlyRolesInAdmin = data?.filter(role => rolesUsedOnlyInAdmin.includes(role.role_id));
  return (onlyRolesInAdmin || []).reduce((acc, item) => {
    const resource = item.resource_attachment;
    const role = item.role_id;

    const hasRolesSpecials = rolesAdminLevel.includes(role);
    const roleIsProperty = role === RBACRole.PropertyAdministrator || role === RBACRole.PropertyCollaborator;
    const isNotOrganizationRole = role !== RBACRole.OrganizationAdministrator && role !== RBACRole.OrganizationCollaborator;
    const roleIsWorkspace = role === RBACRole.WorkspaceOwner;
    const workspaceIdFromItem = item.resource_attachment?.split('workspace:')[1]?.split('/')[0];

    const propertyID = roleIsProperty ? item?.resource_attachment?.split('property/')[1] : '';
    if (resource?.includes(orgId)) {
      if (roleIsProperty && hasPropertyRoles) return acc;
      acc.push({
        org_id: orgId,
        role_context: item.context,
        ...(roleIsProperty && { property_id: propertyID }),
        ...(roleIsProperty && { propertyHashToVerify: `${role}-${propertyID}` }),
        role_id: role,
        workspace_id
      });
    }

    if (hasRolesSpecials && isNotOrganizationRole) {
      acc.push({
        role_context: item.context,
        role_id: role,
        workspace_id,
        app_id: protectorAppId
      });
    }

    if (roleIsWorkspace && workspace_id === workspaceIdFromItem) {
      acc.push({
        role_context: item.context,
        role_id: role,
        workspace_id
      });
    }

    return acc;
  }, Array<RBACFormatToSendProps>());
};

export const getChangedFields = ({ orgId, workspaceId, rbacRoles, userSelected }: GetChangedFieldsProps) => {
  const hasPropertyRoles = rbacRoles?.some(
    role => role.role_id === RBACRole.PropertyAdministrator || role.role_id === RBACRole.PropertyCollaborator
  );

  const previousRoles = adapterAllRolesAllowedByUserForDiff(userSelected?.authorities, orgId, workspaceId, !hasPropertyRoles);
  const nextRoles = rbacRoles || [];

  const objectsAdded: any = differenceBy(nextRoles, previousRoles, 'role_id');
  const objectsRemoved = differenceBy(previousRoles, nextRoles, 'role_id');

  const objectsAddedProperty: any = differenceBy(nextRoles, previousRoles, 'propertyHashToVerify');
  const objectsRemovedProperty = differenceBy(previousRoles, nextRoles, 'propertyHashToVerify');

  const payloadEditRoles: UpdateUserRolesRBACRequestParams = {
    updates: []
  };

  const rolesToRemove = [...objectsRemoved, ...objectsRemovedProperty];
  const rolesToAdd = [...objectsAdded, ...objectsAddedProperty];
  if (rolesToAdd?.length) {
    payloadEditRoles.updates.push({
      operation: RBACActionsEnum.ADD,
      roles: [...objectsAdded, ...objectsAddedProperty]
    });
  }

  if (rolesToRemove?.length) {
    payloadEditRoles.updates.push({
      operation: RBACActionsEnum.REMOVE,
      roles: [...objectsRemoved, ...objectsRemovedProperty]
    });
  }

  return payloadEditRoles;
};

export const getChangedFieldsMultiOrg = async ({ orgId, workspaceId, rbacRoles, userSelected, companiesSelected }) => {
  const previousRoles = adapterAllRolesAllowedByUserForDiff(userSelected.authorities, orgId, workspaceId);
  const nextRoles = rbacRoles || [];

  const orgsWithWorkspaceId = await getWorkspaceIdFromOrgList(companiesSelected);

  const rolesToAdd: any = differenceBy(nextRoles, previousRoles, 'role_id');
  const rolesToRemove = differenceBy(previousRoles, nextRoles, 'role_id');

  const rolesToAddMultiOrgs = orgsWithWorkspaceId
    .map(orgItem => {
      const allRoles = rolesToAdd.map(role => ({
        ...role,
        org_id: orgItem.id,
        workspace_id: orgItem.workspaceID
      }));
      return allRoles;
    })
    .flat();

  const rolesToRemoveMultiOrgs = orgsWithWorkspaceId
    .map(orgItem => {
      const allRoles = rolesToRemove.map(role => ({
        ...role,
        org_id: orgItem.id,
        workspace_id: orgItem.workspaceID
      }));
      return allRoles;
    })
    ?.flat();

  const payloadEditRoles: UpdateUserRolesRBACRequestParams = {
    updates: []
  };

  const divideArray = (rolesArray: any[]) => {
    return rolesArray.reduce((acc: any[][], currentValue: any, index: number) => {
      const currentArrayIndex: number = Math.floor(index / MAX_ROLES_SENT);
      if (!acc[currentArrayIndex]) {
        acc[currentArrayIndex] = [];
      }
      acc[currentArrayIndex].push(currentValue);
      return acc;
    }, []);
  };

  if (rolesToAddMultiOrgs?.length) {
    const paginatedRolesToAdd = divideArray(rolesToAddMultiOrgs);
    paginatedRolesToAdd.forEach(roles => {
      payloadEditRoles.updates.push({
        operation: RBACActionsEnum.ADD,
        roles
      });
    });
  }

  if (rolesToRemoveMultiOrgs?.length) {
    const paginatedRolesToRemove = divideArray(rolesToRemoveMultiOrgs);
    paginatedRolesToRemove.forEach(roles => {
      payloadEditRoles.updates.push({
        operation: RBACActionsEnum.REMOVE,
        roles
      });
    });
  }

  return payloadEditRoles;
};

export const generateInputsForBasicInfoData = ({
  dataInput,
  actionType,
  userType,
  userByEmail
}: GenerateInputsForBasicInfoDataProps): InputForm[] => {
  if (actionType && userType) {
    const inputSelected = allTypesInputs(dataInput);

    if (userType === UserTypeEnum.CONTROLE_CERTO && actionType === ActionTypesEnum.ADD && !userByEmail?.id) {
      inputSelected[userType][actionType].push({
        label: <STTypo color={ColorOptions.PRIMARY}>Password</STTypo>,
        name: 'password',
        initialValue: '',
        size: SizeEnum.LARGE,
        disabled: dataInput.handleDisabled,
        type: InputType.INPUT_PASSWORD_ICON
      });
    }
    return inputSelected[userType][actionType];
  }
  return [];
};

export const buildRolesAdminByPermission = () => {
  const userRoles = store.getState().User.currentUser?.authorities;
  const rolesFiltered = userRoles?.filter((role: ContentRolesRBAC) => rolesAdminLevel.includes(role.role_id));
  const hasOnlyETDENGPermission = rolesFiltered?.length === 1 && rolesFiltered[0].role_id === RolesLevelAdminEnum.ProtectorAdminETDENG;
  if (hasOnlyETDENGPermission) {
    return [rolesOption[2]];
  }
  return rolesOption;
};

export const handleErrorInviteRBAC = (error, dispatch) => {
  if (error?.response) {
    const { response } = error;
    notification('error', response && response?.data?.message);
    dispatch(updateValueIsSaving(false));
    dispatch(toggleAddEditUserModal({ open: false }));
    dispatch(toggleInviteUserByEmailModal(false));
  } else if (error?.message) {
    notification('error', error?.message);
    dispatch(updateValueIsSaving(false));
    dispatch(toggleAddEditUserModal({ open: false }));
    dispatch(toggleInviteUserByEmailModal(false));
  } else {
    notification('error', 'Something went wrong, please try again.');
    dispatch(updateValueIsSaving(false));
    dispatch(toggleAddEditUserModal({ open: false }));
    dispatch(toggleInviteUserByEmailModal(false));
  }
};

export const createRandomPassword = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  let password = '';

  for (let i = 0; i < 5; i++) {
    const randomLetters = letters[Math.floor((window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * letters.length)];
    password += randomLetters;
  }

  for (let i = 0; i < 3; i++) {
    const randomNumber = numbers[Math.floor((window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * numbers.length)];
    password += randomNumber;
  }

  return password;
};

export const handleRolesAndCompaniesChanged = async ({ rolesEdited, companiesSelected }): Promise<UpdateUserRolesRBACRequestParams> => {
  const orgsWithWorkspaceId = await getWorkspaceIdFromOrgList(companiesSelected.map(idOrg => ({ id: idOrg })));

  const allRoles = rolesEdited.map(itemRoleAction => {
    const roles = itemRoleAction.roles
      .map(roleItem => {
        return orgsWithWorkspaceId.map(orgItem => ({
          ...roleItem,
          org_id: orgItem.id,
          workspace_id: orgItem.workspaceID
        }));
      })
      .flat();

    return { ...itemRoleAction, roles };
  });

  const payload = {
    updates: allRoles
  };

  return payload;
};

export const resetInitialState = (initialState, keysTrue: string[] = []) => {
  if (isEmpty(initialState)) return {};
  return Object.entries(initialState).reduce((acc, curr) => {
    return {
      ...acc,
      [curr[0]]: Object.keys(curr[1] as any).reduce((acc, curr) => ({ ...acc, [curr]: keysTrue?.includes(curr) }), {})
    };
  }, {});
};

export const groupRBACRolesByOrgsControleCerto = (roles: GetAllRolesByUserResponse['content'], companiesOptions?: Option[]): string[] => {
  if (!roles?.length || !companiesOptions?.length) return [];

  const companiesInServicesProvides = companiesOptions.map(company => company.id as string);

  if (!companiesInServicesProvides?.length) return [];

  const filteredRoles = roles.filter(role => {
    const resource = role.resource_attachment;
    const companyIsInServiceProvides = companiesInServicesProvides.filter(
      company => typeof company === 'string' && resource.includes(company)
    );
    if (!isEmpty(companyIsInServiceProvides)) {
      return { ...role };
    }
  });

  const rolesWithOrgId = filteredRoles.map(roleFiltered => {
    const resource = roleFiltered.resource_attachment;
    return resource.split('org/')[1];
  });

  const removedDuplicateItems = Array.from(new Set(rolesWithOrgId));

  return removedDuplicateItems;
};

export const getWorkspaceIdFromOrgList = (listCompaniesSelected): Promise<ListOrganizationProvider[]> => {
  return Promise.all(
    listCompaniesSelected.map(async orgItem => {
      const { data } = await getOrganizationInfo(orgItem.id);
      return { ...orgItem, workspaceID: data.workspace_id };
    })
  );
};

export const removeOrAddRolesForMultipleOrgs = async ({
  data,
  action,
  userID,
  companiesOptions,
  changedOrgsList
}: RemoveOrAddRolesForMultipleOrgsProps) => {
  const listCompaniesAdded = companiesOptions?.filter(
    item => item?.id && typeof item?.id === 'string' && changedOrgsList.includes(item.id)
  );
  const orgsWithWorkspaceId = await getWorkspaceIdFromOrgList(listCompaniesAdded);

  Promise.all(
    orgsWithWorkspaceId.map(async orgItem => {
      const rolesToADD = createRoles({
        data,
        orgId: orgItem.id,
        workspaceId: orgItem.workspaceID,
        userTypeSelected: UserTypeEnum.CONTROLE_CERTO,
        noAdminRole: true
      });

      return editRolesRBACByUser(
        {
          updates: [{ operation: action, roles: rolesToADD }]
        },
        userID
      );
    })
  );
};

export const createRegularInvite = async ({ dispatch, orgId, payload }: CreateRegularInviteParams) => {
  const userIsRegistered = store.getState().User.emailUser;
  let userID = userIsRegistered ? userIsRegistered?.id : '';
  const payloadWithTypeUser = {
    ...payload,
    type: 'USER',
    email: payload.email.trim(),
    ...(!userIsRegistered && {
      by_concierge: true,
      worker: true,
      redirect_uri: webPainelUrl,
      client_id: 'protector',
      password: createRandomPassword()
    })
  };

  try {
    if (payloadWithTypeUser?.rbac_roles?.length < MAX_ROLES_SENT_INVITE_REGULAR) {
      await sendAccountInvitationV2(payloadWithTypeUser);
    } else {
      // The value was set to 30 because this end-point gives a lot of timeout when sending many roles.
      const payloadParams = {
        ...payloadWithTypeUser,
        rbac_roles: payloadWithTypeUser.rbac_roles.slice(0, MAX_ROLES_SENT_INVITE_REGULAR)
      };
      await sendAccountInvitationV2(payloadParams);
    }

    if (userIsRegistered) {
      const payloadToEdit = {
        ...userIsRegistered,
        id: userIsRegistered.id,
        job_title: payload.job_title,
        country_code: payload.country_code,
        email: userIsRegistered.email.trim()
      };
      try {
        await editAccountInvitationV2(payloadToEdit as any);

        notification('success', 'Invitation sent successfully.');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    } else {
      try {
        const { data: userDataByEmail } = await getUserDataByEmailV2(payload.email);

        const payloadToEditWithoutRegister = {
          ...userDataByEmail,
          job_title: payload.job_title,
          country_code: payload.country_code,
          email: userDataByEmail.email.trim()
        };
        userID = userDataByEmail.id;
        await editAccountInvitationV2(payloadToEditWithoutRegister);
        notification('success', 'Invitation sent successfully.');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    if (payloadWithTypeUser.rbac_roles.length >= MAX_ROLES_SENT_INVITE_REGULAR) {
      const allRolesSplitted = divideRoleArrayIntoNinetyNine(payloadWithTypeUser.rbac_roles.slice(MAX_ROLES_SENT_INVITE_REGULAR));
      await Promise.allSettled(
        allRolesSplitted.map((item: RBACFormatToSendProps[]) =>
          editRolesRBACByUser(
            {
              updates: [{ operation: RBACActionsEnum.ADD, roles: item }]
            },
            userID
          )
        )
      );

      notification('success', 'Roles successfully assigned!');
    }

    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const editRegularInvite = async ({ dispatch, orgId, payload, workspaceId, userID }: EditRegularInviteParams) => {
  const users = store.getState().User.entities;
  const userSelected = users[userID];

  const payloadEdit: PayloadInviteRBACProps = {
    ...payload,
    id: userID,
    type: 'USER'
  };

  try {
    const payloadEditRoles = getChangedFields({ orgId, workspaceId, rbacRoles: payload.rbac_roles, userSelected });
    delete payloadEdit.rbac_roles;

    await editAccountInvitationV2(payloadEdit);
    notification('success', 'User Data edited successfully!');
    if (payloadEditRoles?.updates?.length) {
      try {
        await editRolesRBACByUser(payloadEditRoles, userID);
        await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
        notification('success', 'User Roles edited successfully!');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const createManagedInvite = async ({ orgId, dispatch, payload }: CreateManagedInviteParams) => {
  try {
    const userData = await createUserManagerV2(payload, orgId);
    notification('success', 'Created user successfully!');
    const userID = userData?.data?.id;

    if (payload?.rbac_roles?.length) {
      try {
        if (payload.rbac_roles.length < MAX_ROLES_SENT) {
          const payloadToAddRoles = {
            updates: [{ operation: RBACActionsEnum.ADD, roles: payload.rbac_roles }]
          };

          await editRolesRBACByUser(payloadToAddRoles, userID);

          notification('success', 'Roles successfully assigned!');
        } else {
          const allRolesSplitted = divideRoleArrayIntoNinetyNine(payload.rbac_roles);

          await Promise.allSettled(
            allRolesSplitted.map((item: RBACFormatToSendProps[]) =>
              editRolesRBACByUser(
                {
                  updates: [{ operation: RBACActionsEnum.ADD, roles: item }]
                },
                userID
              )
            )
          );

          notification('success', 'Roles successfully assigned!');
        }
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    } else {
      notification('success', 'User will not appear without roles.');
    }

    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const editManagedInvite = async ({ userID, orgId, workspaceId, dispatch, dataSent, rbac_roles }: EditManagedInviteParams) => {
  const users = store.getState().User.entities;
  const userSelected = users[userID];

  const payloadWithTypeUser = {
    ...userSelected,
    name: dataSent?.name,
    locale: dataSent?.language,
    country_code: dataSent.country_code,
    phone: dataSent.phone,
    job_title: dataSent.job_title
  };

  try {
    if (!payloadWithTypeUser.authorities?.length) {
      notification('error', 'User must have at least one role (Old authorities).');
      return;
    }

    const payloadEditRoles = getChangedFields({ orgId, workspaceId, rbacRoles: rbac_roles, userSelected });

    await editAccountInvitationV2(payloadWithTypeUser);
    notification('success', 'User Data edited successfully!');

    if (payloadEditRoles?.updates?.length) {
      try {
        await editRolesRBACByUser(payloadEditRoles, userID);
        notification('success', 'User Roles edited successfully!');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_ALL_ROLES_BY_USER, userID] });
    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });

    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const formatPayloadWithTypeUser = ({
  userIsRegistered,
  password,
  payload
}: {
  userIsRegistered: OrgUser | null;
  password: string;
  payload: any;
}): InviteControleCertoParams & PayloadInviteRBACProps => {
  const correctDataToUser = userIsRegistered ? { id: userIsRegistered.id } : { password };
  const inviteId = userIsRegistered ? userIsRegistered.id : null;

  return {
    ...payload,
    ...correctDataToUser,
    type: 'USER',
    email: payload.email,
    org_creation_strategy: 'no',
    invite_id: inviteId,
    terms_accepted_on: moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    opt_ins: []
  };
};

export const updateRolesByUser = async (roles: RBACFormatToSendProps[], userID: string) => {
  await Promise.all(
    roles.map((item: any) =>
      editRolesRBACByUser(
        {
          updates: [{ operation: RBACActionsEnum.ADD, roles: item }]
        },
        userID
      )
    )
  );
};

export const createControleCertoInvite = async ({
  dispatch,
  orgId,
  hasMultipleOrgs,
  rolesSelectsMultipleOrg,
  payload,
  password
}: CreateControleCertoInviteParams) => {
  const userIsRegistered = store.getState().User.emailUser;

  const payloadWithTypeUser = formatPayloadWithTypeUser({ userIsRegistered, password, payload });
  const idUser = payloadWithTypeUser?.id;

  try {
    if (isEmpty(userIsRegistered)) {
      if (hasMultipleOrgs) {
        delete payloadWithTypeUser?.rbac_roles;
      }

      const {
        data: { id: userID }
      } = await createUserControleCertoV2(payloadWithTypeUser);
      notification('success', 'Created user successfully!');
      try {
        try {
          if (hasMultipleOrgs) {
            const roles = Object.values(rolesSelectsMultipleOrg);
            await updateRolesByUser(roles, userID);

            notification('success', 'User Roles edited successfully!');
          }
          if (!hasMultipleOrgs && payload?.rbac_roles?.length) {
            const payloadToAddRoles = {
              updates: [{ operation: RBACActionsEnum.ADD, roles: payload.rbac_roles }]
            };

            await editRolesRBACByUser(payloadToAddRoles, userID);
            notification('success', 'User Roles edited successfully!');
          }
        } catch (error) {
          handleErrorInviteRBAC(error, dispatch);
        }

        await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    if (idUser) {
      try {
        await editAccountInvitationV2(payloadWithTypeUser);
        if (hasMultipleOrgs) {
          const roles = Object.values(rolesSelectsMultipleOrg);
          await updateRolesByUser(roles, idUser);

          notification('success', 'User Roles edited successfully!');
        } else {
          const payloadToAddRoles: any = {
            updates: [{ operation: RBACActionsEnum.ADD, roles: payload.rbac_roles }]
          };
          await editRolesRBACByUser(payloadToAddRoles, idUser);
        }

        await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
        notification('success', 'User Roles edited successfully!');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });
    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const editControleCertoInvite = async ({
  dispatch,
  userID,
  orgId,
  workspaceId,
  hasMultipleOrgs,
  providersByUser,
  companiesOptions,
  data,
  payload
}: EditControleCertoInviteParams) => {
  const users = store.getState().User.entities;
  const userSelected = users[userID];

  const payloadWithTypeUser = {
    ...userSelected,
    name: data?.name,
    locale: data?.language,
    country_code: data.country_code,
    phone: data.phone,
    job_title: data.job_title,
    company: payload.company
  };

  try {
    const payloadEditRoles = getChangedFields({ orgId, workspaceId, rbacRoles: payload.rbac_roles, userSelected });
    await editAccountInvitationV2(payloadWithTypeUser);
    notification('success', 'User Data edited successfully!');
    const hasOnlyCompany = !hasMultipleOrgs && payloadEditRoles?.updates?.length;

    // called when the user isn't multi org
    if (hasOnlyCompany) {
      try {
        await editRolesRBACByUser(payloadEditRoles, userID);
        notification('success', 'User Roles edited successfully!');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    const companiesSelected = payloadWithTypeUser?.company?.length ? payloadWithTypeUser?.company : data?.companiesByServiceProvider;
    const serviceProviders = store.getState().ServiceProviders.companies;
    const companiesByServiceProviderIds = serviceProviders?.map(c => c.id);

    const oldCompanies = providersByUser?.filter(providerId => companiesByServiceProviderIds?.includes(providerId)) ?? [];
    const addedOrgs = differenceBy(companiesSelected, oldCompanies);
    const removedOrgs = differenceBy(oldCompanies, companiesSelected).filter(orgItem => orgItem !== orgId);

    const updatedListOrg = addedOrgs?.length || removedOrgs?.length;
    const jobTitleSelectedHasMultiOrg =
      userSelected?.job_title &&
      (jobTitleWithAccessToMultiOrgSelect.includes(userSelected?.job_title) ||
        jobTitleWithAccessServiceProvider.includes(userSelected?.job_title));

    const jobTitleChanged = userSelected.job_title !== data.job_title && userSelected?.job_title && jobTitleSelectedHasMultiOrg;

    const rolesAndCompaniesChangedTogether = payloadEditRoles?.updates?.length && updatedListOrg;

    // it's going to called when one org was add or remove AND any role was changed
    if (!hasOnlyCompany && rolesAndCompaniesChangedTogether) {
      try {
        const allCompanies = [...new Set([...oldCompanies, ...addedOrgs, orgId])];

        const allOrgsRolesToEdit = await handleRolesAndCompaniesChanged({
          rolesEdited: payloadEditRoles?.updates,
          companiesSelected: allCompanies
        });
        await editRolesRBACByUser(allOrgsRolesToEdit, userID);
        notification('success', 'User Roles edited successfully!');
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    // This will be called when an org has only been deleted or added and the roles have not been changed or the jobtitle user was changed for other that it don't have multi orgs.
    if (
      ((payloadWithTypeUser?.company instanceof Array || data?.companiesByServiceProvider instanceof Array) && updatedListOrg) ||
      (jobTitleChanged && updatedListOrg) ||
      (jobTitleSelectedHasMultiOrg && updatedListOrg && userSelected.job_title === data.job_title)
    ) {
      try {
        if (addedOrgs.length && companiesOptions?.length) {
          await removeOrAddRolesForMultipleOrgs({
            data,
            action: RBACActionsEnum.ADD,
            userID,
            companiesOptions,
            changedOrgsList: addedOrgs
          });
          notification('success', 'User Roles edited successfully!');
        }

        if (removedOrgs.length && companiesOptions?.length) {
          await removeOrAddRolesForMultipleOrgs({
            data,
            action: RBACActionsEnum.REMOVE,
            userID,
            companiesOptions,
            changedOrgsList: removedOrgs
          });
          notification('success', 'User Roles edited successfully!');
        }
      } catch (error) {
        handleErrorInviteRBAC(error, dispatch);
      }
    }

    // called when have multiOrgs and It's update some roles.
    if (hasMultipleOrgs && companiesOptions?.length && !updatedListOrg) {
      const formatCompaniesSelected =
        companiesSelected?.map(id => ({
          id
        })) ?? [];

      formatCompaniesSelected.push({ id: orgId, workspaceID: workspaceId });
      const payloadEditMultiOrgRoles = await getChangedFieldsMultiOrg({
        orgId,
        workspaceId,
        rbacRoles: payload.rbac_roles,
        userSelected,
        companiesSelected: formatCompaniesSelected
      });

      await Promise.allSettled(
        payloadEditMultiOrgRoles.updates.map(item =>
          editRolesRBACByUser(
            {
              updates: [{ operation: item.operation, roles: item.roles }]
            },
            userID
          )
        )
      );

      notification('success', 'User Roles edited successfully!');
    }
    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_ALL_ROLES_BY_USER, userID] });
    await queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgId] });

    dispatchInitialValuesToModals(store.dispatch);
  } catch (error) {
    handleErrorInviteRBAC(error, dispatch);
  }
};

export const verifyHasAnyOrgPermission = roles => {
  if (isEmpty(roles)) return false;
  return Object.keys(roles).some(roleName => rolesLevelOrg.some(rolesLevelOrgItem => roles[roleName][rolesLevelOrgItem]));
};

export const verifyHasAnyPropertyPermission = propertyRoles => {
  if (!propertyRoles?.length) return false;
  return propertyRoles?.some(property => property.role[RBACRole.PropertyAdministrator] || property.role[RBACRole.PropertyCollaborator]);
};

export const divideRoleArrayIntoNinetyNine = (roles: RBACFormatToSendProps[]): RBACFormatToSendProps[][] => {
  return roles?.reduce((acc: RBACFormatToSendProps[][], currentValue: RBACFormatToSendProps, index: number) => {
    const currentArrayIndex: number = Math.floor(index / MAX_ROLES_SENT);
    if (!acc[currentArrayIndex]) {
      acc[currentArrayIndex] = [];
    }

    acc[currentArrayIndex].push(currentValue);

    return acc;
  }, []);
};
