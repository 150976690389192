import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { ErrorMsg, getErrorOrFallbackMessage, notifyErrorOrFallback } from '../../settings/models/Error';
import { commandRequest } from '../../helpers/request/utils';
import { Action, UUID } from '../app/basicModels';
import { getPhenomenonsTreeByCompany } from '../company/service';
import { getPhenomenaByMethodology } from '../methodology/services';
import methodologyDeepActions from '../methodologyDeep/actions';
import { MethodologyDeep, PhenomenonDeep } from '../methodologyDeep/models';
import actions from './actions';
import {
  CloneCharacteristicsIndicatorsDTO,
  ClonedCharacteristicsIndicators,
  LoadPhenomenonQueryPayload,
  Phenomenon,
  PhenomenonPage,
  PhenomenonSingle
} from './models';
import {
  deletePhenomenon,
  getPhenomenon,
  getPhenomenons,
  savePhenomenon,
  getPhenomenonsSimple,
  cloneCharacteristicsIndicators
} from './service';
import {
  upsertPhenomenonInMethodologyDeep,
  updatePhenomenaTree,
  parsePhenomenonSingleToPhenomenon,
  fixPhenomenonPayload,
  getComponentsToAddFromClonedPhenomenon
} from './utils';
import { chunk, cloneDeep, isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import { AppState } from '../redux.model';
import { User } from '../models';
import { getPhenomenaMapFromMethodologyDeep, insertComponentsOnMethodologyDeep } from '../methodologyDeep/services';
import { trackingEvent } from '../../helpers/useTrackingEvents';
import { getValueLanguage } from '../language/utils';

export const handleLoadPhenomenons = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENONS),
    map((action: Action<LoadPhenomenonQueryPayload>) => action.payload),
    exhaustMap((payload: LoadPhenomenonQueryPayload) =>
      getPhenomenons(payload.pageableQuery, payload.companyId, payload.showCanonicals).pipe(
        map(response => response.data),
        map((phenomenons: PhenomenonPage) => {
          return actions.loadPhenomenonsSuccess(phenomenons, payload.companyId);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenons!');
          return of(actions.loadPhenomenonsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadPhenomenonsByMethodology = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENONS_BY_METHODOLOGY),
    map((action: Action<LoadPhenomenonQueryPayload>) => action.payload),
    concatMap((payload: any) =>
      getPhenomenaByMethodology(payload).pipe(
        map(response => response.data),
        map((phenomenons: PhenomenonSingle[]) => {
          const phenonomenonsSigle = phenomenons.map(p => parsePhenomenonSingleToPhenomenon(p) as PhenomenonSingle);
          return actions.loadPhenomenonsByMethodologySuccess(phenonomenonsSigle);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenons by methodology!');
          return of(actions.loadPhenomenonsByMethodologyFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadPhenomenonsSimple = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENONS_SIMPLE),
    map((action: Action<Array<UUID>>) => action.payload),
    concatMap((ids: UUID[]) => {
      return forkJoin(chunk(ids, 500).map(chunkIds => getPhenomenonsSimple(chunkIds).pipe(map(response => response.data)))).pipe(
        map(result => result.flat()),
        map(phenomenons => {
          return actions.loadPhenomenonsSimpleSuccess(phenomenons);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenons simple!');
          return of(actions.loadPhenomenonsSimpleFailure(errorMsg));
        })
      );
    })
  );

export const handleLoadPhenomenon = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENON),
    map((action: Action<UUID>) => action.payload),
    concatMap((phenomenonId: UUID) =>
      getPhenomenon(phenomenonId).pipe(
        map(response => response.data),
        map(phenomenon => {
          return actions.loadPhenomenonSuccess(phenomenon);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenon!');
          return of(actions.loadPhenomenonFailure(errorMsg));
        })
      )
    )
  );

export const handleSavePhenomenon = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PHENOMENON),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.User.currentUser)),
      state$.pipe(map((state: any) => state.PhenomenonCategory.entities)),
      state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep)),
      state$.pipe(map((state: any) => state.Phenomenon.phenomenonsTree))
    ),
    map(([action, methodologySelect, currentUser, phenomenonCategories, methodologyDeep, phenomenonsTree]: any) => {
      const { phenomenon } = action.payload;
      const phenomenonToSave = commandRequest(fixPhenomenonPayload(phenomenon), currentUser);
      if (phenomenon.isNew) {
        if (methodologySelect && methodologySelect.company_id && !phenomenonToSave.payload.company_id) {
          phenomenonToSave.payload.company_id = methodologySelect.company_id;
        }
      }
      return {
        ...action,
        payload: phenomenonToSave,
        methodologyId: action.payload.methodologyId,
        orderedPhenomenonId: action.payload.orderedPhenomenonId,
        orderedInspectionGroupId: action.payload.orderedInspectionGroupId,
        phenomenonCategories,
        methodologyDeep,
        phenomenonsTree
      };
    }),
    concatMap((action: any) => {
      const { orderedPhenomenonId, orderedInspectionGroupId } = action;
      const phenomenon = action.payload;
      const { phenomenonCategories } = action;
      const { methodologyDeep } = action;
      const phenomenaTree: Phenomenon[] = action.phenomenonsTree;

      if (isString(phenomenon.payload.category)) {
        phenomenon.payload.category = phenomenonCategories[phenomenon.payload.category];
      }
      const { isNew } = phenomenon.payload;
      return savePhenomenon(phenomenon, isNew).pipe(
        concatMap(_ => {
          if (action.methodologyId && methodologyDeep) {
            const mutableMethodologyDeep: MethodologyDeep = upsertPhenomenonInMethodologyDeep(
              methodologyDeep,
              phenomenon.payload,
              orderedPhenomenonId,
              orderedInspectionGroupId,
              phenomenaTree
            );

            const mutablePhenomenaTree: Phenomenon[] = updatePhenomenaTree(phenomenon.payload, phenomenaTree);

            return concat([
              actions.savePhenomenonSuccess(phenomenon.payload),
              methodologyDeepActions.updateMethodologyDeep(mutableMethodologyDeep),
              actions.updatePhenomenonTree(mutablePhenomenaTree)
            ]);
          }
          return of(actions.savePhenomenonSuccess(phenomenon.payload));
        }),
        catchError((error: any) => {
          const fixedMessage = 'Phenomenon could not be updated';
          const processedMessage = notifyErrorOrFallback(error, fixedMessage);

          // this console is proposal for try capture this error.
          // eslint-disable-next-line no-console
          console.error({
            action,
            phenomenonCategories,
            methodologyDeep,
            fixedMessage,
            error,
            phenomenon,
            processedMessage
          });
          Sentry.captureException(JSON.stringify({ fixedMessage, error, phenomenon, processedMessage }));

          return of(actions.savePhenomenonFailure(processedMessage));
        })
      );
    })
  );

export const handleDeletePhenomenon = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PHENOMENON),
    map((action: Action<Phenomenon>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.User.currentUser))),
    map(([phenomenon, currentUser]: any) => commandRequest(phenomenon, currentUser)),
    withLatestFrom(state$.pipe(map((state: any) => state.Phenomenon.entities))),
    concatMap(([phenomenon, phenomenons]) => {
      if (!phenomenon.payload.company_id) {
        phenomenon.payload = phenomenons[phenomenon.payload.id];
      }
      return deletePhenomenon(phenomenon).pipe(
        map(response => {
          notification('success', 'Phenomenon deleted!');
          return actions.deletePhenomenonSuccess(phenomenon.payload);
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error deleting phenomenon!');
          return of(actions.deletePhenomenonFailure(msg));
        })
      );
    })
  );

export const handleLoadPhenomenonsTree = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENONS_TREE),
    map((action: Action<LoadPhenomenonQueryPayload>) => action.payload),
    concatMap((payload: any) =>
      getPhenomenonsTreeByCompany(payload.pageableQuery, payload.companyId).pipe(
        map(response => response.data),
        map((phenomenonsTreePage: any) => {
          return actions.loadPhenomenonsTreeSuccess(phenomenonsTreePage.content);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenons!');
          return of(actions.loadPhenomenonsTreeFailure(errorMsg));
        })
      )
    )
  );

export const handleSavePhenomenonSuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PHENOMENON_SUCCESS),
    withLatestFrom(state$.pipe(map((state: any) => state.Phenomenon.editModalActive))),
    concatMap(([{ payload }, editModalActive]): Observable<any> => {
      notification('success', `${payload.isNew ? 'Save' : 'Update'} Phenomenon Success!`);
      if (editModalActive) {
        return concat([actions.togglePhenomenonEditModal(), actions.unselectPhenomenon()]);
      }
      return of();
    })
  );

export const handleCloneCharacteristicIndicatorToPhenomenon = (
  action$: ActionsObservable<Action<CloneCharacteristicsIndicatorsDTO[]>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON),
    map((action: Action<CloneCharacteristicsIndicatorsDTO[]>) => action.payload ?? ({} as CloneCharacteristicsIndicatorsDTO[])),
    withLatestFrom(
      state$.pipe(
        map(state => ({
          currentUser: state.User.currentUser as User,
          methodologyDeep: state.MethodologyDeep.methodologyDeep as MethodologyDeep
        }))
      )
    ),
    concatMap(([payload, state]) => {
      const itemsToClone = payload.map(item => commandRequest(item, state.currentUser));

      return cloneCharacteristicsIndicators(itemsToClone[0]).pipe(
        concatMap(response => {
          const remainingItems = itemsToClone.slice(1);
          const remainingRequests = remainingItems.map(item =>
            cloneCharacteristicsIndicators(item).pipe(catchError((error: ErrorMsg) => of(getErrorMessage(error))))
          );
          return forkJoin([of(response), ...remainingRequests]);
        }),
        concatMap(response => {
          let mutableMethodologyDeep = cloneDeep(state.methodologyDeep);
          let clonedVariables = 0;
          let clonedIndicators = 0;
          let clonedPhenomena = 0;

          const clonedItems: ClonedCharacteristicsIndicators[] = [];
          const errors: string[] = [];
          response.forEach(item => {
            if (typeof item === 'string') {
              errors.push(item);
            } else {
              clonedItems.push(item.data);
            }
          });

          let phenomenaDeepMap = new Map<string, PhenomenonDeep>();
          if (clonedItems.length) {
            phenomenaDeepMap = getPhenomenaMapFromMethodologyDeep(state.methodologyDeep);
          }

          clonedItems
            .filter(component => !isEmpty(component.characteristics) || !isEmpty(component.indicators))
            .forEach(clonedItem => {
              const { characteristics, indicators } = clonedItem;
              const phenomenon: Partial<Phenomenon> = {
                id: clonedItem.destiny_phenomenon_id,
                characteristics,
                indicators
              };

              clonedVariables += characteristics.length;
              clonedIndicators += indicators.length;
              clonedPhenomena += 1;

              const destinationPhenomenon = phenomenaDeepMap.get(phenomenon.id as string);
              const orderedPhenomenonId = destinationPhenomenon?.id as string;

              const componentsToAddFiltered = getComponentsToAddFromClonedPhenomenon(phenomenon, destinationPhenomenon);

              mutableMethodologyDeep = insertComponentsOnMethodologyDeep(
                mutableMethodologyDeep,
                orderedPhenomenonId,
                componentsToAddFiltered
              );
            });

          if (errors.length) {
            // eslint-disable-next-line no-console
            console.log('Errors cloning characteristics and indicators', errors);
          }

          const errorNotification = errors.length ? `, and error cloning ${errors.length} phenomena` : '';
          const notificationStatus = errors.length ? 'warning' : 'success';
          const notificationMessage = `${clonedItems.length} phenomena cloned with success${errorNotification}!`;
          notification(notificationStatus, notificationMessage);

          const { id: userId, job_title: jobTitle, name: userName } = state.currentUser;
          const { id: methodologyId, crop, company } = mutableMethodologyDeep;

          trackingEvent('Cloned Variables and Indicators', {
            userId,
            jobTitle,
            userName,
            methodologyId,
            cropId: crop?.id,
            cropName: getValueLanguage(crop?.name),
            companyId: company?.id,
            companyName: company?.name,
            clonedVariables,
            clonedIndicators,
            clonedPhenomena
          });

          return concat([
            methodologyDeepActions.addCharacteristicIndicatorsSuccess(mutableMethodologyDeep),
            actions.cloneCharacteristicsIndicatorsToPhenomenonSuccess(clonedItems, errors),
            methodologyDeepActions.updateClonedPhenomenaTotals(clonedVariables, clonedIndicators, clonedPhenomena),
            methodologyDeepActions.toggleCloneCharacteristicIndicatorDrawer()
          ]);
        }),
        catchError((error: ErrorMsg) => {
          let errorMsg = error?.response?.data?.detail as string;
          if (errorMsg) {
            // eslint-disable-next-line no-console
            console.log('Errors cloning characteristics and indicators', errorMsg);
            notification('error', errorMsg);
          } else {
            errorMsg = notifyErrorOrFallback(error, 'Error cloning characteristics and indicators!');
          }
          return of(actions.cloneCharacteristicsIndicatorsToPhenomenonFailure([errorMsg]));
        })
      );
    })
  );

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function getErrorMessage(error: ErrorMsg) {
  let errorMsg = error?.response?.data?.detail as string;
  if (errorMsg) {
    return errorMsg;
  }

  errorMsg = getErrorOrFallbackMessage(error, 'Error cloning characteristics and indicators!');
  return errorMsg;
}
